.navbar {
    position: fixed;  
    width: 100%;      
    top: 0;           
    left: 0;          
    z-index: 1030;    
}

.navbar-toggler {
    border: none !important;  
}
  
.navbar-toggler-icon {
    width: 30px;   
    height: 25px;  
}
  
  
.navbar-toggler:focus {
    box-shadow: none;
}

.consultation-btn {
    color: #4CAF50 !important;  
    border: none;  
    padding: 8px 15px;  
    border-radius: 5px; 
    transition: background-color 0.3s; 
}

.consultation-btn:hover {
    background-color: #45a049;  
}
  