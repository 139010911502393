.card {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column; 
    height: 100%; 
}

.card-body {
    flex-grow: 1; 
    display: flex; 
    flex-direction: column; 
}

.card-text {
    flex-grow: 1; 
}

.green-btn {
    background-color: #006400; 
    color: #fff;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.green-btn:hover {
    background-color: #45a049; 
}