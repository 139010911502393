.container {
    background-color: #fff; 
    padding: 2rem 1rem; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}
  
h2 {
    color: #0056b3; 
    font-weight: bold;
}
  
.service-row h3 {
    color: #0056b3; 
    margin-bottom: 1rem; 
    font-weight: 500;
    text-align: center; 
}
  
.service-row ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;  
    text-align: center; 
    display: flex; /* Using flex to align children (li) */
    flex-direction: column; /* Align li items in a column */
    align-items: center; /* Center align the items in the column */
    width: 100%; /* Use full width to allow flex to center content */
}

.service-row li {
    padding: 0.5rem 0;
    width: 100%; /* Ensures li tags use full width for centering text */
}

.service-row .col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Ensures everything in the column is centered */
}

.service-row img {
    max-width: 100%; /* Ensures images are responsive and fit within the column */
    height: auto; /* Maintains aspect ratio */
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .service-row {
        flex-direction: column-reverse;
        align-items: center; /* Center-aligns content for mobile view */
    }

    .service-row img {
        margin-bottom: 1rem;
    }

    .service-row .col-md-6 {
        align-items: center;
    }
}

  