.footer {
    background-color: #0056b3 !important; 
    position: relative;
    bottom: 0;
    width: 100%;
    height: 60px;
}

#foot {
    background-color: #0056b3;
    color: #fff;
    box-shadow: none;
    border-radius: 0px;
}
  