.landing-page-background {
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 1) 100%),
    url("helper.png");
  background-size: cover;
  background-position: center;
  height: 50vh;
  width: 100vw;
  display: flex;
  align-items: center;  
  justify-content: flex-end; /* Aligns content to the right */
}

.container-background {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  min-width: 250px; /* Minimum width */
  width: 33%; /* Set width to exactly one third */
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
}

